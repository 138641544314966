import React, { PureComponent } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { TimelineLite } from 'gsap';
import Observer from 'react-intersection-observer';
import { Link, graphql } from 'gatsby';

import Img from 'gatsby-image';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import ReadMore from '../../static/assets/Readmore-white.svg';
import LightBlueKey from '../../static/assets/light_blue_key.svg';
import YellowKey from '../../static/assets/yellow_key.svg';
import '../sass/_looking-for.scss';
import * as globalAnimation from '../animations/globalAnimation';
import * as LookingFor from '../animations/LookingFor';

export default class ourMission extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.tl = new TimelineLite({ force3D: !0, yoyo: true });
  }

  componentDidMount() {
    globalAnimation.animateHeading(this.tl, '.title', '.subtitle');
    globalAnimation.animateImageXRight(
      this.tl,
      '.svg-line__line_lightblue',
      '.svg_circle_lightblue',
      '.looking-for-image',
      '.lightblue_bg',
      '#title',
      '#desc',
      '#btn_anim',
      '50px',
      '0px'
    );
  }

  animateSecondtHeading = InView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      globalAnimation.animateHeading(tl, '.second-title', '.second-subtitle');
    }
  };

  animateSectionTwo = InView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      globalAnimation.animateImageXLeft(
        tl,
        '.svg-line__line_yellow',
        '.svg_circle_yellow',
        '.looking-for-image2',
        '.orange_bg2',
        '#looking-for-title2',
        '#looking-for-description2',
        '#btn_anim',
        '-50px',
        '0px'
      );
    }
  };

  animateSectionThree = InView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      LookingFor.animateImageContainer(
        tl,
        '.bg_lookigfor',
        '#looking-for-title3',
        '.box-container'
      );
    }
  };

  animateWorkshopDescription = InView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      globalAnimation.animateHeading(tl, '.case-workshop-description');
    }
  };

  animateSectionFour = InView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      LookingFor.animateLookinForImage(
        tl,
        '.svg-line__line_lightblue',
        '.svg_circle_lightblue',
        '.looking-for-image4',
        '.lightblue_bg4',
        '#looking-for-description4',
        '-120',
        '50px',
        '-50px'
      );
    }
  };

  animateSectionFive = InView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      LookingFor.animateLookinForImage(
        tl,
        '.svg-line__line_yellow',
        '.svg_circle_yellow',
        '.looking-for-image5',
        '.orange_bg5',
        '#looking-for-description5',
        '120',
        '-50px',
        '-50px'
      );
    }
  };

  render() {
    const {
      data: {
        markdownRemark: {
          frontmatter: {
            title,
            sectionOne,
            sectionTwo,
            sectionThree,
            sectionThree: { lists },
            caseWorkshops,
            caseWorkshops: { sectionFour, sectionFive },
          },
        },
      },
    } = this.props;
    return (
      <Layout navbarColor="appcolor">
        <SEO pageTitle="What is BluePrint looking for?" />

        <Container fluid="true">
          <Row>
            <Col>
              <div className="main_wrapper">
                <div className="wrapper px-0">
                  <div className="page_heading_section text-center">
                    <h3 className="page_heading text-uppercase d-lg-block d-none title">{title}</h3>
                    <h4 className="text-uppercase page_heading_child subtitle">{title}</h4>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="mb-md-5">
            <Col xs={12} className="mb-md-5 d-lg-none">
              <div className="bg-appcolor-light square mx-auto my-md-4 my-lg-0">
                <div className="">
                  <Img
                    className=" img-fluid cover_pic square"
                    fluid={sectionOne.image.childImageSharp.fluid}
                  />
                </div>
              </div>
            </Col>
            <Col xs={12} md={5} className="mb-md-5 d-none d-lg-block">
              <div className="bg-appcolor-light lightblue_bg square mx-auto my-md-4 my-lg-0">
                <div className="">
                  <Img
                    className=" img-fluid looking-for-image cover_pic square"
                    fluid={sectionOne.image.childImageSharp.fluid}
                  />
                </div>
              </div>
            </Col>
            <Col xs={12} md={{ span: 6, offset: 1 }} className="mb-md-5 ">
              <div className="my-4 my-md-0">
                <div className="position-relative line line-horizontal mt-3">
                  <LightBlueKey />
                </div>
                <p id="desc">{sectionOne.description}</p>
              </div>
            </Col>
          </Row>
          <Observer onChange={this.animateSectionTwo} triggerOnce>
            <Row>
              <Col xs={12} className="mb-md-5 order-1 order-md-2 d-lg-none">
                <div className="bg-orange square mx-auto my-md-4 my-lg-0">
                  <div className="">
                    <Img
                      className=" img-fluid cover_pic square"
                      fluid={sectionTwo.image.childImageSharp.fluid}
                    />
                  </div>
                </div>
              </Col>

              <Col
                xs={12}
                md={{ span: 5, offset: 1 }}
                className="mb-md-5 order-1 order-md-2 d-none d-lg-block"
              >
                <div className="bg-orange orange_bg2 square mx-auto my-md-4 my-lg-0">
                  <div className="">
                    <Img
                      className=" img-fluid looking-for-image2 cover_pic square"
                      fluid={sectionTwo.image.childImageSharp.fluid}
                    />
                  </div>
                </div>
              </Col>

              <Col xs={12} md={6} className="order-2 order-md-1 ">
                <div className="my-4 my-md-0">
                  <div className="position-relative line line-horizontal mt-3">
                    <YellowKey />
                  </div>
                  <p id="looking-for-description2">{sectionTwo.description}</p>
                </div>
              </Col>
            </Row>
          </Observer>
        </Container>

        <Observer onChange={this.animateSectionThree} triggerOnce>
          <Container fluid="true">
            <Row className="h-100">
              <Col xs={12} lg={12} className="px-0 h-100 w-100">
                <div className="d-none d-md-block">
                  <div>
                    <Img
                      className="bg_lookigfor h-auto"
                      fluid={sectionThree.image.childImageSharp.fluid}
                    />
                  </div>
                  <div className="bg-text d-flex align-items-center w-100 h-100">
                    <div className="mx-auto text-center">
                      <h5 id="looking-for-title3" className="text-white mb-3">
                        {sectionThree.title}
                      </h5>
                      <Container>
                        <Row className="box-container">
                          {lists.map((list, index) => (
                            <Col md={list.span} className="py-3 text-center" key={String(index)}>
                              <div className="bg-white h-100 d-flex align-items-center justify-content-center p-3">
                                <h6 className="text-appcolor mb-0 font-weight-normal">
                                  {list.text}
                                </h6>
                              </div>
                            </Col>
                          ))}
                          <Col lg={12}>
                            <div className="page_heading_section text-center pt-lg-3">
                              <div className="d-flex justify-content-center white-learnmore">
                                <div className="py-3">
                                  <Link to="/apply-now/" className="bordered-btn text-center">
                                    Apply now
                                    <ReadMore
                                      className="arrow-translate mb-0"
                                      height="10px"
                                      width="40px"
                                    />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </div>
                </div>
                {/* mobile responsive */}
                <div className="d-md-none">
                  <div className="bg-mobile-core-behaviors p-2 p-lg-5 w-100 h-100">
                    <div className="my-4 text-center">
                      <h6 className="text-white">{sectionThree.title}</h6>
                      <div className="box-container d-flex flex-column justify-content-center">
                        {lists.map((list, index) => (
                          <Col xs={10} className="py-3 mx-auto text-center" key={String(index)}>
                            <div className="bg-white p-3 p-lg-4">
                              <h6 className="text-appcolor mb-0 font-weight-normal">{list.text}</h6>
                            </div>
                          </Col>
                        ))}
                        <Col md={12}>
                          <div className="d-flex justify-content-center">
                            <div className="white-learnmore py-3">
                              <Link to="/apply-now/" className="bordered-btn text-center">
                                Apply Now
                                <ReadMore
                                  className="arrow-translate mb-0"
                                  height="10px"
                                  width="40px"
                                />
                              </Link>
                            </div>
                          </div>
                        </Col>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Observer>
        <Container>
          <Observer onChange={this.animateSecondtHeading} triggerOnce>
            <Row>
              <Col xs={12}>
                <div>
                  <div className="wrapper">
                    <div className="page_heading_section text-center">
                      <h3 className="page_heading text-uppercase d-lg-block d-none second-title mb-0">
                        {caseWorkshops.title}
                      </h3>
                      <h4 className="text-uppercase page_heading_child second-subtitle mb-0">
                        {caseWorkshops.title}
                      </h4>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Observer>
          <Row className="justify-content-center justify-content-md-between">
            <Observer onChange={this.animateWorkshopDescription} triggerOnce>
              <Col xs={11} md={12} className="my-3 my-md-0 mx-auto">
                <p
                  className="text-appcolor text-semibold mb-5 case-workshop-description"
                  style={{ lineHeight: '150%', marginTop: '-10px' }}
                >
                  {caseWorkshops.description}
                </p>
              </Col>
            </Observer>

            <Container>
              <Observer onChange={this.animateSectionFour} triggerOnce>
                <Row>
                  <Col xs={12} className="d-lg-none">
                    <div className="bg-appcolor-light square mx-auto">
                      <div className="">
                        <Img
                          className=" img-fluid square"
                          fluid={sectionFour.image.childImageSharp.fluid}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col xs={12} lg={5} className="my-md-5 d-none d-lg-block">
                    <div className="bg-appcolor-light lightblue_bg4 square mx-auto my-md-4 my-lg-0">
                      <div className="">
                        <Img
                          className=" img-fluid looking-for-image4 cover_pic square"
                          fluid={sectionFour.image.childImageSharp.fluid}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col xs={12} lg={{ span: 6, offset: 1 }} className="my-md-5">
                    <div className="my-4 my-md-0">
                      <div className="position-relative line line-horizontal mt-3">
                        <LightBlueKey />
                      </div>
                      <p id="looking-for-description4">{sectionFour.description}</p>
                    </div>
                  </Col>
                </Row>
              </Observer>
              <Observer onChange={this.animateSectionFive} triggerOnce>
                <Row>
                  <Col xs={12} className="my-md-5 order-1 order-md-2 d-lg-none">
                    <div className="bg-orange square mx-auto my-md-4 my-lg-0">
                      <div className="">
                        <Img
                          className=" img-fluid cover_pic square"
                          fluid={sectionFive.image.childImageSharp.fluid}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col
                    xs={12}
                    md={{ span: 5, offset: 1 }}
                    className="my-md-5 order-1 order-md-2 description_column d-none d-lg-block"
                  >
                    <div className="bg-orange orange_bg5 square mx-auto my-md-4 my-lg-0">
                      <div className="">
                        <Img
                          className=" img-fluid looking-for-image5 cover_pic square"
                          fluid={sectionFive.image.childImageSharp.fluid}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col xs={12} lg={6} className="my-md-5 order-2 order-md-1">
                    <div className="my-4 my-md-0">
                      <div className="position-relative line line-horizontal mt-3">
                        <YellowKey />
                      </div>
                      <p id="looking-for-description5">{sectionFive.description}</p>
                    </div>
                  </Col>
                </Row>
              </Observer>
            </Container>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    markdownRemark(frontmatter: { page: { eq: "looking-for" } }) {
      frontmatter {
        title
        sectionOne {
          description
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        sectionTwo {
          description
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        sectionThree {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          lists {
            text
            span
          }
        }
        caseWorkshops {
          title
          description
          sectionFour {
            description
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          sectionFive {
            description
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
